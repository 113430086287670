@import "src/styles/sass";

.paper {
  background: $palette-inverted;
  @include shadow;
  border-radius: 16px;

  &--primary {
    padding: 32px 16px;

    @include media-up(md) {
      padding: 32px;
    }
  }

  &--secondary {
    padding: 16px;
  }

  &--third {
    padding: initial;
  }

  &--fourth {
    padding: 32px 16px;

    @include media-up(md) {
      padding: 32px 32px 0;
    }
  }

  &--fifth {
    padding: 32px 16px;

    @include media-up(md) {
      padding: 48px;
    }
  }

  &--sixth {
    padding: 24px 16px;

    @include media-up(md) {
      padding: 40px 32px;
    }
  }
}
