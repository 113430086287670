@import "src/styles/sass";

.briefcase-skeleton {
  padding: 32px 16px;
  @include flex-centered($direction: column);
  gap: 24px;

  @include media-up(md) {
    padding: 32px;
  }

  &__info {
    width: 100%;

    &-row {
      width: 100%;
      @include flex($justify: space-between);

      &:first-of-type {
        margin-bottom: 8px;
      }
    }
  }

  &__block {
    padding: 24px;

    &-title {
      margin-bottom: 14px;
    }

    &-item {
      width: 100%;
      padding: 14px 0;
      @include flex($direction: column);
      gap: 8px;

      &-row {
        width: 100%;
        @include flex($justify: space-between);
      }
    }
  }
}
