@import "src/styles/sass";

.briefcase-free-cash {
  $self: &;
  @include flex($direction: column);
  @include shadow;
  padding: 24px 32px;
  background: $palette-icon-gradient;
  border-radius: 16px;
  gap: 4px;

  @include media-up(md) {
    gap: 8px;
  }

  &--main {
    @include flex($direction: column, $justify: center, $align: flex-start);
    width: 100%;
    padding: 16px;
    gap: 16px;

    @include media-up(sm2) {
      @include flex($direction: row, $justify: space-between, $align: center);
    }
  }

  &__text {
    @include body3;

    @include media-up(lg) {
      @include body2;
      font-weight: normal;
    }
  }

  &__title {
    @include flex($justify: flex-start, $align: center);
    position: relative;
    gap: 8px;
    color: $palette-secondary;

    .icon {
      width: 23px;
      height: 15px;

      &:hover {
        cursor: pointer;
      }
    }

    #{$self}--main & {
      @include flex(
        $direction: row-reverse,
        $align: center,
        $justify: flex-start
      );
      width: 100%;
      gap: 12px;

      @include media-up(sm2) {
        width: auto;
      }
    }
  }

  &__switcher-block {
    @include flex($align: center);
    gap: 10px;

    #{$self}--main & {
      @include flex($direction: row, $justify: flex-end, $align: center);
      width: 100%;
      gap: 12px;

      @include media-up(sm2) {
        width: auto;
      }
    }
  }

  &__icon {
    @include svg-size(18px, 18px);
    @include svg-color($palette-btn-hover);
    cursor: pointer;
    opacity: 0.5;
  }

  &__text-icon {
    @include svg-size(12px, 12px);
    @include svg-color($palette-secondary);
    cursor: pointer;
  }

  &__value {
    @include h6;
    @include gradient-text($palette-gradient-accent);
    @include flex($align: flex-end, $justify: center);
    white-space: nowrap;

    @include media-up(md) {
      @include h4;
    }

    #{$self}--main & {
      @include subtitle1;
      @include gradient-text($palette-btn);
      @include flex($direction: row, $align: center);
    }
  }
}
