@import "src/styles/sass";

.autocomplete {
  width: inherit;

  .ingos-dropdown__menu {
    max-width: 100%;
  }

  .ingos-dropdown__menu-item {
    width: 100%;
    word-break: break-word;
    white-space: break-spaces;
  }

  &__icon {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 20px;
    color: black;
    @include svg-color($palette-secondary);
    cursor: pointer;
  }

  &__input {
    @include media-down(sm1) {
      .ingos-input__validation-text {
        margin-top: 0;
      }
    }
    position: relative;

    .ingos-input__elem {
      padding-right: 35px;
      @include text-ellipsis;
    }

    .ingos-input {
      display: block;
      width: 100%;
    }

    &--opened {
      .autocomplete__icon {
        transform: rotate(180deg);
      }
    }
  }
}
