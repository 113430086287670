@import "src/styles/sass";

.update-pwa {
  @include flex-centered($direction: column);
  padding: 40px;
  gap: 32px;
  width: 328px;

  @include media-up(md) {
    padding: 48px;
    gap: 48px;

    width: 656px;
  }

  &__title {
    @include h6;
    margin: 0;

    @include media-up(md) {
      @include h5;
    }
  }

  &__action {
    width: 100%;

    @include media-up(md) {
      width: 260px;
    }
  }
}
