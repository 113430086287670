@import "src/styles/sass";

.briefcase-modal {
  @include flex-centered($direction: column);
  padding: 10px 16px 32px;
  row-gap: 16px;

  @include media-up(md) {
    padding: 32px 48px;
    max-width: 656px;
    row-gap: 32px;
  }

  &__title {
    @include h6;

    @include media-up(md) {
      @include h4;
    }
  }

  &__description {
    @include body1;

    > p {
      margin: 0;

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }

  &__important {
    @include body0;
  }

  &__reference {
    @include caption12;
    color: $palette-secondary;
  }

  &__action {
    width: 100%;

    @include media-up(md) {
      width: 260px;
    }
  }
}
