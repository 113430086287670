@import "src/styles/sass";

.app-skeleton {
  padding: 10px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  @include flex($direction: column);

  &__main {
    padding: 10px;
    @include flex($direction: column);
  }

  &__header {
    @include flex($justify: space-between, $align: center);
    margin-bottom: 40px;
  }

  &__content {
    flex: 1;
  }

  &__tabs {
    @include flex($justify: space-between);
    margin-bottom: 32px;
  }
}

@include media-up(xl) {
  .app-skeleton {
    padding: 10px 200px;
  }
}
