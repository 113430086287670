@import "src/styles/sass";

.briefcase-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
  margin: 0;
  width: 100%;
  height: 100%;
  gap: 32px;
  background: $palette-constant-white;
  @include shadow;
  border-radius: 16px;

  @include media-up(xl) {
    gap: 32px;
    padding: 32px 32px 34px;
  }

  .briefcase-block {
    width: 100%;
    @include subtitle3;

    @include media-up(md) {
      display: flex;
    }

    &__savings {
      margin-bottom: 8px;

      @include media-up(md) {
        flex: 1 1 auto;
        margin-bottom: 0;
      }

      &--title {
        color: $palette-secondary;
        @include caption12;
        margin-bottom: 4px;
      }

      &--hoarding {
        color: $palette-constant-black;
        @include h5;
      }
    }

    &__total {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: baseline;
      gap: 4px 8px;

      @include media-up(md) {
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
      }

      &--title {
        color: $palette-secondary;
        @include caption12;
        text-transform: lowercase;

        @include media-up(md) {
          text-transform: none;
        }
      }
    }

    &__total-profit {
      @include subtitle3;

      @include media-up(md) {
        @include h7($weight: 500);
      }

      &--green {
        color: $palette-success;
      }

      &--red {
        color: $palette-constant-red;
      }

      &--secondary {
        color: $palette-secondary;
      }
    }
  }
}
