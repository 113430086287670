@import "src/styles/mixins";

html {
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  height: 100%;
}

body {
  font-family: IngoStem, Arial, Verdana, Courier, serif;
  font-style: normal;
  background-color: $palette-bg-gray-2;
  width: 100%;
  height: 100%;
  color: $palette-primary;

  #root {
    width: 100%;
    height: 100%;
  }
}

html,
body {
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  scroll-behavior: auto;
  -webkit-overflow-scrolling: auto;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

h1 {
  @include h5;

  @include media-up(md) {
    font-weight: 650;
    font-size: 24px;
    line-height: 48px;
  }

  @include media-up(lg) {
    font-weight: 650;
    font-size: 34px;
    line-height: 48px;
  }

  @include media-up(xl) {
    @include h2;
  }
}
