@import "src/styles/sass";

.auth-header {
  @include gradient-svg-color;
  @include flex($direction: column, $justify: center);

  height: 60px;

  @include media-up(md) {
    height: 72px;
  }

  &__logo {
    @include svg-size(98px, 24px);
    @include media-up(md) {
      @include svg-size(164px, 40px);
    }
  }
}
