@import "src/styles/sass";

.briefcase-collapsed-wrapper {
  width: 100%;
  background-color: $palette-btn-secondary;
  border-radius: 16px;

  .briefcase-items {
    overflow: hidden;
    @include flex($direction: column);
    gap: 16px;
  }

  @include media-up(md) {
    .briefcase-items {
      gap: 0;
    }
    padding: 24px;
    background-color: $palette-bg-gray-2;
  }

  .empty-product {
    cursor: pointer;
  }

  .briefcase-box {
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 14px;
    }

    &__title {
      @include subtitle3;
    }

    &__title-text {
      @include gradient-text($palette-gradient-accent);
    }

    &__subtitle {
      @include caption12;
      color: $palette-secondary;

      &.chevron-up {
        transform: rotate(0);
      }

      &.chevron-down {
        transform: rotate(180deg);
      }

      @include media-up(md) {
        span {
          display: none;
        }
      }
    }
  }

  .case-items {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .briefcase-slogan {
    color: $palette-constant-black;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 20px 0 20px;

    @include media-up(md) {
      margin: 28px 0 28px;
    }
  }

  .briefcase__actions {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    gap: 12px;
    width: 100%;

    & button,
    & a {
      width: calc(50% - 6px);
    }

    @include media-up(md) {
      @include body0;
    }
  }

  .briefcase-item__actions-buttons > .ingos-btn {
    width: 100%;
  }
}
