@import "src/styles/sass";

.suspense-loader {
  .app-skeleton {
    padding: 0;
    height: 50vh;
    margin-top: 32px;
    @include footer-margin;
  }
}
