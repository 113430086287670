@import "src/styles/sass";

.briefcase-single {
  width: 100%;
  @include flex($direction: column);

  &__type {
    color: $palette-constant-blue-60;
    @include subtitle3;
  }

  &__title {
    @include h7;
    margin-bottom: 32px;
  }

  &__info {
    padding: 16px;
    border-radius: 16px;
    background-color: $palette-bg-gray-2;
    margin-bottom: 32px;
    @include flex($direction: column);

    &-title {
      color: $palette-secondary;
      @include caption12;
      margin-bottom: 8px;
    }

    &-main {
      @include flex($direction: column);
    }

    &-sum {
      @include h7;
      margin-bottom: 8px;
    }

    &-profit {
      @include subtitle3;
      margin-bottom: 8px;
      color: $palette-secondary;

      &--positive {
        color: $palette-success;
      }

      &--negative {
        color: $palette-constant-red;
      }
    }

    &-pif {
      color: $palette-secondary;
      @include mini-caption10;
      @include flex($direction: column);
    }
  }

  &__actions {
    gap: 12px;

    .ingos-btn {
      width: 100%;
    }
    @include flex($justify: space-between);

    &--single {
      justify-content: flex-end;
    }
  }
}

@include media-up(md) {
  .briefcase-single {
    &__info-main {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__info-pif {
      flex-direction: row;

      &-date {
        order: -1;
        margin-right: 5px;
      }
    }
  }

  .briefcase-single__info-pif-date::after {
    content: " ●";
  }
}
