@import "src/styles/sass";

.common-wrapper {
  padding: 0 8px;

  @include media-up(sm) {
    padding: 0 16px;
  }

  @include media-up(md) {
    padding: 0 44px;
  }

  &.hidden-overflow {
    overflow: hidden;
  }

  &__container {
    max-width: 1216px;
    margin: 0 auto;
  }
}
