@import "src/styles/sass";

.custom-tooltip {
  @include flex($direction: row, $align: flex-start);
  max-width: 277px;
  gap: 12px;

  &__title-container {
    @include flex($direction: column);
    gap: 15px;
  }

  &__title-icon {
    @include gradient-svg-color;
    width: 18px;
    height: 18px;
  }

  &__title {
    @include body3;
    color: $palette-constant-black;
  }

  &__subtitle {
    @include mini-caption10;
    color: $palette-constant-black;
  }
}
