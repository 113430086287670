@import "src/styles/sass";

.auth-layout {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #ecf6ff 0%, #f1ecff 100%);

  :where(.common-wrapper, .common-wrapper__container) {
    height: 100%;
  }

  &__background-logo {
    position: absolute;
    content: "";

    @include media-down(md) {
      top: 138px;
      padding: 0 13px;

      //width: 334px;
      width: 100%;

      //height: 364px;
    }

    @include media-up(md) {
      right: 0;
      bottom: 0;
      width: 827px;
      height: 900px;
    }
  }

  &__wrapper {
    @include flex($direction: column);

    width: 100%;
    height: 100%;
  }

  &__content {
    @include flex-centered;

    flex: 1;
    z-index: 1;
  }
}
