@import "src/styles/sass";

.switcher {
  @include flex($direction: column, $align: flex-start);
  background: $palette-soft;
  position: relative;
  padding: 4px;
  gap: 8px;
  border-radius: 16px;

  &__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__menu {
    @include flex($direction: column);
    background: $palette-soft;
    position: absolute;
    gap: 8px;
    z-index: 1;
    top: 0;
    left: 0;
    border-radius: 16px;
    padding: 4px;
  }

  &__selected--is-active {
    @include flex($direction: row, $align: center, $justify: center);
    @include shadow;
    background: $palette-constant-white;
    padding: 4px 16px;
    gap: 8px;
    border-radius: 22px;
    @include gradient-text($palette-btn);
  }

  &__selected {
    cursor: pointer;
    @include flex($direction: row, $align: center, $justify: center);
    @include subtitle3;
    border-radius: 22px;
    padding: 4px 16px;
    gap: 8px;
    color: $palette-secondary;
  }

  &__selected:hover {
    @include gradient-text($palette-btn);
  }
}
