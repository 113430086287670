.snackbar {
  position: fixed;
  right: 3.5rem;
  margin-left: 3.5rem;
  z-index: 999;

  max-width: 343px;
  width: auto;
  animation: 300ms ease-out forwards slide-up;
  overflow: hidden;

  &--top {
    top: 3.5rem;
  }

  &--bottom {
    bottom: 3.5rem;
  }
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
