@import "src/styles/sass";

.briefcase-item {
  @include caption12;
  @include hidden-scroll;
  position: relative;
  overflow: auto;
  display: grid;
  gap: 8px;
  background-color: $palette-bg-gray-2;
  border-radius: 16px;
  padding: 16px;

  @include media-up(md) {
    padding: 8px 0;
  }

  &--hover {
    grid-template-areas:
      "title total"
      "pif-info total"
      "buttons buttons"
      "actions actions";

    @include media-up(md) {
      margin-bottom: -40px;

      &:hover {
        padding-bottom: calc(40px + 16px);

        .briefcase-item__buttons {
          display: flex;
          opacity: 1;
        }
      }
    }
  }

  &--product {
    transition: all $transition;

    grid-template-areas:
      "title total"
      "pif-info pif-info"
      "actions actions";

    @include media-up(md) {
      grid-template-areas:
        "title total"
        "pif-info total"
        "buttons buttons"
        "actions actions";

      .briefcase-item__actions,
      .briefcase-item__toggle {
        display: none;
      }
    }
  }

  &__toggle {
    @include svg-color($palette-primary);
    margin: auto 0 0 auto;

    svg {
      transition: transform $transition;
    }

    &--rotated svg {
      transform: rotate(180deg);
    }
  }

  &__actions {
    @include flex($direction: column);
    grid-area: actions;

    &-buttons {
      @include flex($justify: space-between);
      margin-top: 8px;
      gap: 12px;

      @include media-down(sm) {
        @include flex($direction: column);
      }

      &--single {
        @include flex(flex-end);
      }
    }

    &-button > .briefcase-item__btn {
      width: 100%;

      &:first-child {
        margin-bottom: 8px;
      }
    }

    &-button--single > .ingos-btn {
      width: calc(50% - 6px);

      &:first-of-type {
        margin-bottom: 8px;
      }
    }
  }

  &__title {
    @include caption12;
    grid-area: title;
    color: $palette-constant-black;

    @include media-up(md) {
      @include body2;
    }

    &--is-hold {
      color: $palette-secondary;
    }
  }

  &__pif-info {
    grid-area: pif-info;
    @include flex($align: center);

    &-icon {
      opacity: 0.5;
      margin-right: 6px;
      @include svg-size(12px);
      @include svg-color($palette-btn-hover);
    }

    &-icon-lock {
      @include svg-size(16px);
    }

    .pif-info {
      @include mini-caption10;
      @include flex($wrap: wrap);
      color: $palette-secondary;

      @include media-up(md) {
        @include flex($justify: flex-end, $direction: row-reverse);
      }

      & div:nth-child(1)::before {
        content: "\00B7";
        margin: 0 4px;
      }

      &__quantity {
        order: 2;
      }

      &__price-per-unit {
        order: 1;
      }

      &__date {
        order: 3;
        width: 100%;

        @include media-up(md) {
          width: auto;
        }
      }

      @include media-up(md) {
        & div:not(:last-child)::before {
          content: "\00B7";
          margin: 0 4px;
        }

        &__quantity {
          order: 1;
        }

        &__price-per-unit {
          order: 2;
        }
      }
    }
  }

  &__comment,
  &__comment div {
    @include mini-caption10;
    color: $palette-secondary;
  }

  &__tm-description {
    @include flex($align: center);
    gap: 6px;
  }

  &__tm-description-icon {
    @include svg-size(12px);
    @include svg-color($palette-btn-hover);

    flex-shrink: 0;
  }

  &__total {
    &-fin {
      @include flex($align: center);
    }

    grid-area: total;
    @include flex($align: flex-end, $direction: column);
    white-space: nowrap;

    @include media-up(md) {
      @include body1;
      justify-content: space-between;
    }

    .cost-absolute {
      @include caption12;
      color: $palette-constant-black;
      margin-bottom: 2px;

      @include media-up(md) {
        margin-bottom: 10px;
      }
    }

    .cost-relative {
      @include caption12;
      color: $palette-secondary;

      &.black {
        color: $palette-constant-black;
      }

      &.green {
        color: $palette-success;
      }

      &.red {
        color: $palette-constant-red;
      }
    }
  }

  .briefcase-item__buttons {
    display: none;
    transition: top $transition, opacity $transition;
    opacity: 0;
    grid-area: actions;
    width: 100%;
    background-color: $palette-btn-secondary;

    .briefcase-item__btn {
      width: 100%;
    }

    @include media-up(md) {
      @include flex-centered($justify: flex-end);
      gap: 8px;
      background-color: $palette-bg-gray-2;
    }
  }

  .briefcase-item__btn {
    &--hide {
      visibility: hidden;
    }
  }
}
